import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { CustomersService } from "./customers.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { JobService } from "../job/job-data.service";
@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent implements OnInit {
  isUpdate = false;
  isView = false;
  showLink = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  cols = [
    {
      header: "Big change Id",
      field: "companyId",
    },
    {
      header: "Company Name",
      field: "companyName",
    },
  ];
  customerList = [];
  selectedCustomer: any;
  customerData: any;
  isCategory = false;
  linkData: any;
  isDisposal = false;
  companyList = [];
  adminUserList = [];
  showLoader = true;
  selectedAdmin: any;
  searchArr = [];
  constructor(
    private service: CustomersService,
    private router: Router,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private JobService: JobService
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.fetchCustomerList();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }
  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }
  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.isView = false;
    this.customerData = {};
    this.cdr.detectChanges();
    this.fetchCustomerList();
  }
  createCustomer(event) {
    console.log("create staff called");
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.customerData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else if (this.showLink == true) {
      this.showLink = false;
      this.isCategory = false;
      this.linkData = {};
      this.isDisposal = false;
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }
  editCustomer(event, data) {
    this.service.companyDetails(data.companyId).subscribe((response) => {
      console.log("user  details", response);
      this.customerData = response.data;
      this.showAdd = true;
      console.log("user  details", this.customerData);

      this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });

      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchCustomerList();
      //     }
      // });
    });
  }
  viewCustomer(event, data) {
    this.service.companyDetails(data.companyId).subscribe((response) => {
      console.log("user  details", response);
      this.customerData = response.data;
      this.isView = true;
      this.showAdd = true;
      console.log("user  details", this.customerData);

      this.cdr.detectChanges();
    });
  }
  deleteCustomer(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Customer?",
      accept: () => {
        this.service.deleteCompany(id).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.messageService.add({
                severity: "success",
                summary: "Delete Customer",
                detail: "Customer details deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Customer",
                detail: data.error.message,
              });
            }
            this.fetchCustomerList();
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Customer",
              detail: "Failed to delete the Customer",
            });
            this.fetchCustomerList();
          }
        );
        //  });
      },
    });
  }
  fetchCustomerList() {
    const staffArr = [];
    this.service.companyList().subscribe((response) => {
      console.log("staff  details", response);

      // response.data.forEach((ele) => {
      //     ele.isActive = ele.active ? 'Yes' : 'No';
      // });
      // if (response.data.length > 0){
      //   response.data.forEach(element => {
      //     console.log('element',element,this.companyID);
      //     if (this.isAcumen == 'true' || this.isAcumen == true){
      //       staffArr.push(element);
      //     } else{
      //       if(element.company.companyId == this.companyID){
      //         staffArr.push(element);
      //       }
      //     }
      //   });
      // }
      this.customerList = response.data;
      this.showLoader = false;
    });
  }
  listDisposal(event, data) {
    this.service.companyDetails(data.companyId).subscribe((response) => {
      console.log("user  details", response);
      this.linkData = response.data;
      this.showLink = true;
      this.isDisposal = true;
      this.cdr.detectChanges();
    });
    //  this.router.navigate(['customers/listdisposalsites/'+data.companyId]);
  }
  listCategory(event, data) {
    this.service.companyDetails(data.companyId).subscribe((response) => {
      console.log("user  details", response);
      this.linkData = response.data;
      this.showLink = true;
      this.isCategory = true;
      this.cdr.detectChanges();
    });
    //  this.router.navigate(['customers/listwastecategory/'+data.companyId]);
  }
  closeAll(event) {
    this.showLink = false;
    this.isCategory = false;
    this.linkData = {};
    this.isDisposal = false;
    this.cdr.detectChanges();
    this.fetchCustomerList();
  }
  checkPermissions(type, module) {
    return this.JobService.checkPermissions(type, module);
  }
  navigateToUsersAccess(id) {
    this.router.navigate(["user-permission", id]);
  }
}
